<template>
  <div class="views-page is-flex is-flex-direction-column px-4 py-4">
    <div
      class="info-line"
      :class="GetMessagesData.main_color"
    ></div>
    <div class="column p-0 tag-parent-mobile is-flex is-flex-wrap-wrap">
      <span
        class="tag-mobile is-size-6 has-text-weight-medium tag px-4 py-4 mr-3 mb-2"
        v-for="(Msg,id) in GetMessagesData.cards"
        :key="Msg.id"
        :class="Msg.color"
      >{{ Msg.name }}</span>
    </div>
    <div
      id="chartdiv"
      ref="chartdiv"
    ></div>
    <b-loading
      :is-full-page="false"
      v-model="isLoading"
      :can-cancel="false"
    />
    <div>
      <div style="max-width: 500px">
        <b-field label="Выберите канал">
          <!-- @input="SendData" -->
          <b-select
            placeholder="Название"
            v-model="selected_channel"
            :loading="isTypesLoading"
            style="min-width: 20rem"
            expanded
            @input="onSelectChange"
          >
            <option
              v-for="(option, idx) in channels"
              :value="option"
              :key="idx"
            >
              {{ option }}
            </option>
          </b-select>
        </b-field>
        <div class="is-flex is-flex-direction-column mb-3">
          <b-field label="Период отображения данных:">
            <b-datepicker
              v-model="dates"
              range
              :first-day-of-week="1"
              icon="calendar-days"
              icon-pack="fas"
              placeholder="Выберите даты"
              position="is-top-right"
              class="trends-spec__datepicker"
            >
              <div class="columns is-multiline is-gapless">
                <div class="column is-half" style="padding: 2px !important;">
                  <b-button
                    class="column is-half"
                    style="height: auto; width: 100%"
                    label="Сегодня"
                    type="is-info"
                    size="is-small"
                    @click="handleDatesChange(1, 'day')"
                  />
                </div>
                <div class="column is-half" style="padding: 2px !important;">
                  <b-button
                    class="column is-half"
                    style="height: auto; width: 100%"
                    label="Неделя"
                    type="is-info"
                    size="is-small"
                    @click="handleDatesChange(7 , 'day')"
                  />
                </div>
                <div class="column is-half" style="padding: 2px !important;">
                  <b-button
                    class="column is-half"
                    style="height: auto; width: 100%"
                    label="Месяц"
                    type="is-info"
                    size="is-small"
                    @click="handleDatesChange(1, 'month')"
                  />
                </div>
                <div class="column is-half" style="padding: 2px !important;">
                  <b-button
                    class="column is-half"
                    style="height: auto; width: 100%"
                    label="Очистить"
                    size="is-small"
                    type="is-danger"
                    outlined
                    @click="dates = []" />
                </div>
              </div>
            </b-datepicker>
          </b-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4lang_ru_RU from "@amcharts/amcharts4/lang/ru_RU";
import dayjs from "dayjs"

export default {
  props: ["device_id"],
  data: () => ({
    chart: null,
    names: [],
    date: {},
    dates: [],
    channels: [],
    ListSelect: {},
    isLoading: false,
    isTypesLoading: false,
    spectraData: null,
    selected_channel: null,
    selected: null,
    isShown: false,
    currentData: null,
    trendColor: ["#FAA700", "#6DD400", "#0091FF", "#6236FF", "#B620E0", "#9B9B9B", "#44D7B6", "#0D7B80", "#22A699", "#F2BE22", "#F29727", "#47A992", "#FFE194"],
  }),
  computed: mapGetters(["GetMessagesData"]),
  watch: {
    dates(newValue, oldValue) {
      if(newValue && newValue !== oldValue) {
        this.date = {
          from: dayjs(newValue[0] || new Date()).valueOf(),
          to: dayjs(newValue[1] || new Date()).valueOf()
        }
        this.fetchByDate()
      }
    }
  },
  mounted() {
    // this.device_id = this.$router.history.current.params.id.replace(/[^0-9]/g,"");
    this.fetchSpecTrendsSelect(this.device_id);

    // this.device_id = this.$router.history.current.params.id.replace(/[^0-9]/g,"");
    this.FetchMessages(this.device_id);
    //create an instance of amCharts
    this.chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);

    //language
    this.chart.language.locale = am4lang_ru_RU;

    // Add data
    // this.chart.data = this.spectraData;

    // Create axes date X
    var dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.minGridDistance = 100;


    // First value axis Y
    var valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "мм/с";
    valueAxis.renderer.line.strokeOpacity = 1;
    valueAxis.renderer.line.strokeWidth = 2;
    valueAxis.title.rotation = 90;
    valueAxis.title.valign = "top";
    valueAxis.title.fontWeight = 600;

    this.ValueAxis = valueAxis;


    // Second value axis
    var valueAxisSpeed = this.chart.yAxes.push(new am4charts.ValueAxis());
    valueAxisSpeed.renderer.line.strokeOpacity = 1;
    valueAxisSpeed.renderer.line.strokeWidth = 2;
    valueAxisSpeed.renderer.opposite = true;
    valueAxisSpeed.title.text = "об/мин";
    valueAxisSpeed.title.rotation = 90;
    valueAxisSpeed.title.valign = "top";
    valueAxisSpeed.title.fontWeight = 600;

    //create legend of series
    this.chart.legend = new am4charts.Legend();

    //create event to cursor
    this.chart.cursor = new am4charts.XYCursor();
  },
  beforeDestroy() {
    this.chart.dispose()
  },
  methods: {
    ...mapMutations(["ChangeNavName"]),
    ...mapActions(["FetchMessages"]),
    handleDatesChange(value, unit) {
      const from = dayjs().subtract(value, unit)
      const to = dayjs()
      this.dates = [new Date(from.format()), new Date(to.format())]
    },
    async fetchSpecTrendsSelect(id) {
      this.isTypesLoading = true;
      try {
        const { data } = await this.$axios.get(`user/agents/${id}/trend-specter-types`)
        this.channels = data?.data?.channel ?? []
        if(this.channels[0]) {
          this.selected_channel = this.channels[0]
          this.handleDatesChange(1, 'day')
        }
      } catch (e) {
        console.error(e)
        throw e
      } finally {
        this.isTypesLoading = false;
      }
    },
    // async getSpectraData(data) {
    //   this.isLoading = true;
    //   await this.$axios.post(`user/agents/${data.id}/trend-specters`, data.data).then(response => {
    //     this.spectraData = response.data.data;
    //     this.$set(this.ListSelect, "channel", response.data.channels);
    //     this.isLoading = false;
    //   });
    // },
    onSelectChange() {
      if(this.dates.length === 2) this.fetchByDate()
    },
    createSeries(field, name, zindex, color) {
      const series = this.chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = field;
      series.dataFields.dateX = "time";
      series.name = name;
      series.tooltip.fillcolor = color;
      series.tooltipText = "{name} {dateX}: [b]{valueY}[/]";
      series.strokeWidth = 2;
      series.stroke = am4core.color(color);
      series.zIndex = zindex;
      // series.yAxis = valueAxis;
      return series;
    },
    fillChart() {
      const key = this.chart.series.length;
      for (let i = 0; i < key; i++) {
        this.chart.series.removeIndex(0).dispose();
      }

      for (let j = 0; j < this.names.length; j++) {
        this.createSeries("avg" + (j + 1), this.names[j], j, this.trendColor[j]);
      }

      this.chart.data = this.spectraData;
    },
    async fetchByDate() {
      this.isLoading = true
      try {
        const { data } = await this.$axios.post(`user/agents/${this.device_id}/trend-specters`, {
          channel: this.selected_channel,
          to: this.date.to,
          from: this.date.from
        })
        this.spectraData = data.data ?? [];
        this.names = data.names ?? []
        this.fillChart()
      } catch (e) {
        console.error(e)
        throw e
      } finally {
        this.isLoading = false
      }
    },
  },
};
</script>

<style scoped>
.highlight {
  background: #61a937;
}
</style>
